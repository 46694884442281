export const useVivnetworks = () => {

  const registerAffiliatedRequest = (requestQuery) => {
    if (requestQuery) {
      const shopZone = useShopZone().value;
      let cookieDomain = shopZone.domain.replace("www.", ".");

      if (requestQuery.cjevent) {
        useCookie('cje', {
          secure: true,
          maxAge: 60 * 60 * 24 * 30 * 13,
          domain: cookieDomain
        }).value = requestQuery.cjevent;
      }

      if (requestQuery.utm_campaign && requestQuery.utm_content) {
        useCookie('vivnetworks_campaign', {
          secure: true,
          maxAge: 60 * 60 * 24 * 30 * 13,
          domain: cookieDomain
        }).value = requestQuery.utm_campaign;

        useCookie('vivnetworks_content', {
          secure: true,
          maxAge: 60 * 60 * 24 * 30 * 13,
          domain: cookieDomain
        }).value = requestQuery.utm_content;
      }
    }
  }

  const getCjEventId = () => {
    return useCookie('cje').value?.toString();
  }

  const getCampaign = () => {
    return useCookie('vivnetworks_campaign').value?.toString();
  }

  const getContent = () => {
    return useCookie('vivnetworks_content').value?.toString();
  }

  return {
    registerAffiliatedRequest,
    getCjEventId,
    getCampaign,
    getContent
  }
}